import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper'
import { Link } from 'gatsby'

import Layout from "../components/layout";
import SEO from "../components/seo";
import USP from "../components/home/usp";
import Button from "../components/buttons/button";
import SecondaryButton from "../components/buttons/secondary-button";

import heroImage from "../images/home/hero-image.png";
import learnersUSP from "../images/home/learners.png";
import driversUSP from "../images/home/drivers.png";
import insuranceUSP from "../images/home/insurance.png";
import gambushe from "../images/home/Gambushe.png";
import freeman from "../images/home/Freeman.png";
import masole from "../images/home/Masole.png";
import rodrick from "../images/home/Rodrick.png";
import sicelo from "../images/home/Sicelo.png";
import william from "../images/home/William.png";
import customerOne from "../images/home/customer-one.png";
import insuranceImage from "../images/home/insurance-section.png";
import scooterImage from "../images/home/scooter.png";
import desktop from "../images/home/desktop.png";
import nubeecarImage from "../images/home/nubee-car.png";
import yellowVector from "../images/home/yellow-vector.png";
import starVector from "../images/home/star-bg.png";
import instructorVector from "../images/home/instructor-vector.png";
import nubeeOne from "../images/home/nubee-one.jpg";
import nubeeTwo from "../images/home/nubee-two.jpg";
import nubeeThree from "../images/home/nubee-three.jpg";
import nubeeFour from "../images/home/nubee-four.jpg";
import nubeeFive from "../images/home/nubee-five.jpg";
import nubeeSix from "../images/home/nubee-six.jpg";

import map from "../images/home/map.png";

import tagIcon from "../icons/tag.svg";
import medalIcon from "../icons/star-medal.svg";
import clipboardIcon from "../icons/clipboard.svg";
import locationIcon from "../icons/location-icon.svg";
import facebookIcon from "../icons/facebook.png";
import linkedinIcon from "../icons/linked-in.png";
import instagramIcon from "../icons/instagram.png";
import twitterIcon from "../icons/twitter.png";
import tiktokIcon from "../icons/tik-tok.png";
import browseIcon from "../icons/browse.svg";
import bookIcon from "../icons/book.svg";
import safeIcon from "../icons/safe.svg";
import clockIcon from "../icons/clock.svg";
import thumbsIcon from "../icons/thumbs-up.svg";
import lightbulbIcon from "../icons/light-bulb.svg";
import Hotjar from '@hotjar/browser';

const siteId = 3536400;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

import 'swiper/css';
import 'swiper/css/navigation';

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Nubee`,
          `driving school`,
          `learners`,
          `artificial intelligence`,
        ]}
        title="Home"
      />

      <section className="md:p-0 text-left mt-28 md:mt-24">
        <div className="flex flex-col gap-5 md:flex-row px-6 md:max-w-7xl mx-auto">
          <div className="md:pr-2">
            <div>
              <h1 className="inline-block mb-4 text-5xl md:text-6xl font-bold text-black font-heading relative z-20">
                {"South Africa's"} driving education platform
              </h1>
              <div className="absolute z-0 top-48 md:top-56 md:left-14 2xl:left-80 3xl:left-1/4">
                <img className="z-0" src={yellowVector}></img>
              </div>
            </div>
            <div className="relative">
              <p className="py-8 text-black font-body">
                Browse verified instructors in your area and book lessons today
              </p>
              <div className="flex flex-col gap-5 md:w-11/12 relative z-20">
                {/*
                  <a href="/learners" title="Learners License">Prepare for your test</a>
*/}

                <USP
                  title="Learners Licence"
                  description="Prepare for your test"
                  href="/learners"
                ></USP>
                {/*
                  <Button title="Find Insurance Savings" href="https://nubee.co.za/insurance"></Button>
*/}

                <USP
                  title="Drivers Licence"
                  description="Book driving lessons"
                  href="/drivers-vehicle-selection"
                ></USP>

                {/*
                  <a href="/drivers-vehicle-selection" title="Drivers Licence">Book driving lessons</a>
*/}
                <USP
                  title="Car Insurance"
                  description="Get cover within the hour"
                  href="/insurance"
                ></USP>
                {/*                  <a href="/insurance" title="Insurance">Easiest way to compare and buy car insurance</a>*/}
              </div>
              <div className="absolute z-0 top-8 -left-20">
                <img className="z-0 opacity-30" src={starVector}></img>
              </div>
            </div>
          </div>
          <img
            alt="driving school illustration"
            src={heroImage}
            className="hidden md:inline-block object-contain w-3/4 md:w-3/6 mx-auto"
          />
        </div>
      </section>

      <section className="bg-nubee-green p-8 py-16 mt-10 md:mt-32 md:py-20 ">
        <div className="flex flex-col gap-16 md:flex-row justify-between text-black px-6 md:max-w-6xl mx-auto">
          <div className="text-center justify-items-center">
            <img className="mx-auto pb-5" src={tagIcon} />
            <h1 className="text-2xl font-bold">Affordable Prices</h1>
            <p className="text-sm font-body">
              Prices starting from R200 per lesson
            </p>
          </div>
          <div className="text-center justify-items-center">
            <img className="mx-auto pb-5" src={medalIcon} />
            <h1 className="text-2xl font-bold">Expert Instructors</h1>
            <p className="text-sm font-body">
              Find vetted instructors in your area
            </p>
          </div>
          <div className="text-center justify-items-center">
            <img className="mx-auto pb-5" src={clipboardIcon} />
            <h1 className="text-2xl font-bold">100% Pass Rate</h1>
            <p className="text-sm font-body">We have a winning recipe</p>
          </div>
        </div>
      </section>

      <section className="mt-10 md:mt-24 px-6 md:max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row gap-10 md:gap-26 items-center">
          <img className="w-2/3 md:w-2/4" src={desktop} />
          <div className="md:p-20 text-center md:text-left md:pr-0">
            <h1 className="mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
              {"It's"} never been easier
            </h1>
            <ul className="flex flex-col gap-5 text-black text-left text-xl py-5 w-3/4 mx-auto md:mx-0">
              <li className="flex self-start items-center gap-8">
                <img className="w-8" src={browseIcon} />
                <p>Browse instructors in your area</p>
              </li>
              <li className="flex self-start items-center gap-8">
                <img className="w-8" src={bookIcon} />
                <p>Book lessons online</p>
              </li>
              <li className="flex self-start items-center gap-8">
                <img className="w-8" src={safeIcon} />
                <p>Cashless and safe</p>
              </li>
            </ul>
            <Button
              title="Get started"
              href="https://app.nubee.co.za/#/auth/login"
            ></Button>
          </div>
        </div>
      </section>

      <section className="bg-nubee-purple mt-10 md:mt-24 py-10 md:py-28">
        <h1 className="text-5xl md:text-6xl font-bold text-black font-heading text-center px-6 md:max-w-7xl mx-auto">
          Our Services
        </h1>
        <p className="text-black font-body text-center w-3/4 md:w-2/4 mx-auto pt-5">
          We are your wing man from day one
        </p>
        <div className="flex flex-col md:flex-row justify-between mt-16 text-black px-6 md:max-w-7xl mx-auto">
          <div className="text-center justify-items-center">
            <img className="mx-auto pb-5" src={learnersUSP} />
            <h1 className="text-3xl md:text-2xl font-bold">Learners Licence</h1>
            <p className="text-s w-4/5 mx-auto pt-3">
              Access to free learning material just for you
            </p>
            <Button title="Start Learning" href="/learners"></Button>
            {/*
            <SecondaryButton title="Start Learning" href="https://nubee.co.za/learners"></SecondaryButton>
*/}
          </div>
          <div className="text-center justify-items-center">
            <img className="mx-auto pb-5" src={driversUSP} />
            <h1 className="text-3xl md:text-2xl font-bold">Drivers Licence</h1>
            <p className="text-s w-4/5 mx-auto pt-3">
              Find your perfect instructor, book them and pass
            </p>
            <Button title="Book Now" href="/drivers-vehicle-selection"></Button>

            {/*
            <SecondaryButton title="Book Now" href="https://nubee.co.za/drivers-vehicle-selection"></SecondaryButton>
*/}
          </div>
          <div className="text-center justify-items-center">
            <img className="mx-auto pb-5 pt-10 md:pt-0" src={insuranceUSP} />
            <h1 className="text-3xl md:text-2xl font-bold">
              Vehicle Insurance
            </h1>
            <p className="text-s w-4/5 mx-auto pt-3">
              Save cash by choosing the best policy suited to you
            </p>

            <Button
              title="Find Insurance Savings"
              href="/drivers-vehicle-selection"
            ></Button>

            {/*
            <SecondaryButton title="Find Insurance Savings" href="https://nubee.co.za/insurance"></SecondaryButton>
*/}
          </div>
        </div>
      </section>

      <section className="mt-24 md:px-16">
        <div className="flex flex-col md:flex-row gap-10 md:gap-32 items-center px-6 md:max-w-7xl mx-auto">
          <div className="">
            <h1 className="mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
              Launch your driving school with us.
            </h1>
            <p className="text-black">
              Stop searching for customers and start attracting them!
            </p>
            <ul className="text-black pt-3 list-style">
              <li>
                1. Let our platform connect you with a steady stream of eager
                learners, while you focus on doing what you do best - building
                your business and sharing your expertise with the world.
              </li>
              <li className="pt-3">
                2. With our innovative lesson management system, you can finally
                take charge of your schedule and streamline your operations, all
                from one convenient and user-friendly platform
              </li>
            </ul>
            <Button
              title="Get Started"
              href="https://app.nubee.co.za/#/auth/registration/personal-info"
            ></Button>
          </div>
          <img
            className="w-3/4 md:w-2/4 order-first md:order-last"
            src={nubeecarImage}
          />
        </div>
      </section>

      <div className="border-b-2 border-nubee-blue mt-24 w-32 mx-auto"></div>

      <section className="mt-10 md:mt-24 px-6 md:max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row gap-10 md:gap-32 items-center px-6 md:max-w-7xl mx-auto">
          <img className="w-3/4 md:w-3/5" src={insuranceImage} />
          <div className="">
            <h1 className="mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
              The easiest way to shop for car insurance.
            </h1>
            <ul className="flex flex-col gap-5 text-black text-left text-xl py-5 w-3/4 mx-auto md:mx-0">
              <li className="flex self-start items-center gap-8">
                <img className="w-8" src={clockIcon} />
                <p>Sign up in 30 secs</p>
              </li>
              <li className="flex self-start items-center gap-8">
                <img className="w-8" src={thumbsIcon} />
                <p>Get quotes</p>
              </li>
              <li className="flex self-start items-center gap-8">
                <img className="w-8" src={lightbulbIcon} />
                <p>Switch and save by simply clicking a button</p>
              </li>
            </ul>
            <Button title="Find Insurance Savings" href="/insurance"></Button>
          </div>
        </div>
      </section>

      <div className="border-b-2 border-black mt-24 w-32 mx-auto"></div>

      <section className="md:px-28 mt-24 px-6 md:max-w-7xl mx-auto">
        <h1 className="mb-4 text-5xl md:text-6xl font-bold text-black font-heading text-center">
          Our Instructors
        </h1>
        <p className="text-black text-center w-3/4 md:w-3/4 mx-auto pt-5">
          Find your perfect instructor by browsing their profiles with
          independent reviews.
        </p>
        <div className="mt-24 relative">
          <Swiper
            breakpoints={{
              // when window width is >= 640px
              360: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 3,
              },
            }}
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-navigation-size": "25px",
            }}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            modules={[Navigation]}
            navigation
            spaceBetween={0}
            slidesPerView={3}
            onSlideChange={() => console.log("slide change")}
            onSwiper={swiper => console.log(swiper)}
          >
            <SwiperSlide>
              <img className="mx-auto pb-5" src={gambushe} />
              <div className="text-center w-60 mx-auto">
                <h2 className="font-bold">Gambushe</h2>
                <p className="mt-4">
                  Gambushe is an experienced driving instructor with a patient
                  and friendly approach. Learners should use him for safe,
                  confident and stress-free driving.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={sicelo} />
              <div className="text-center w-60 mx-auto">
                <h2 className="font-bold">Sicelo</h2>
                <p className="mt-4">
                  Sicelo is a passionate driving instructor who goes the extra
                  mile to help learners pass their test. Learners should use him
                  for customized training and practical tips.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={rodrick} />
              <div className="text-center w-60 mx-auto">
                <h2 className="font-bold">Rodrick</h2>
                <p className="mt-4">
                  Rodrick is a hard-working and patient driving instructor who
                  prioritizes the safety and success of his learners. They
                  should use him for reliable and comprehensive training.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={masole} />
              <div className="text-center w-60 mx-auto">
                <h2 className="font-bold">Masole</h2>
                <p className="mt-4">
                  Masole is an innovative and enthusiastic driving instructor
                  who brings a fresh perspective to learning. Learners should
                  use him for fun, engaging and personalized lessons.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={william} />
              <div className="text-center w-60 mx-auto">
                <h2 className="font-bold">William</h2>
                <p className="mt-4">
                  William is a humble, kind and down-to-earth driving instructor
                  who takes pride in his {"learners'"} success. They should use
                  him for patient and personalized training.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={freeman} />
              <div className="text-center w-60 mx-auto">
                <h2 className="font-bold">Freeman</h2>
                <p className="mt-4">
                  Freeman is a friendly and experienced driving instructor who
                  creates a supportive learning environment. Learners should use
                  her for stress-free and confidence-building driving lessons.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="absolute z-0 -top-20 -left-5">
            <img className="z-0" src={instructorVector}></img>
          </div>
        </div>
        <div className="text-center mt-10">
          <Button
            title="Book now"
            href="/drivers-vehicle-selection"
          ></Button>
        </div>
      </section>

      <section className="px-6 md:px-28 mt-24 bg-nubee-green py-28 text-center">
        <h1 className="mb-4 text-5xl md:text-6xl font-bold text-black font-heading text-center">
          {"Let's"} get you behind the wheel.
        </h1>
        <p className="text-black text-center md:w-2/4 mx-auto py-5">
          {"Don't"} be the friend that is still asking for a lift.
        </p>
        <img className="w-2/3 md:w-1/3 mx-auto" src={scooterImage} />
        <Button
          title="Sign up"
          href="https://app.nubee.co.za/#/auth/login"
        ></Button>
      </section>

      <div className="border-b-2 border-black mt-24 w-32 mx-auto"></div>

      <section className="md:px-28 mt-24 px-6 md:max-w-7xl mx-auto">
        <h1 className="text-5xl md:text-6xl font-bold text-black font-heading text-center md:w-3/4 m-auto">
          Check out our community of Nubees.
        </h1>
        <div className="mt-10 md:mt-24">
          <Swiper
            breakpoints={{
              // when window width is >= 640px
              360: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 3,
              },
            }}
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-navigation-size": "25px",
            }}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            modules={[Navigation]}
            navigation
            spaceBetween={40}
            slidesPerView={2}
            onSlideChange={() => console.log("slide change")}
            onSwiper={swiper => console.log(swiper)}
          >
            <SwiperSlide>
              <img className="mx-auto pb-5" src={nubeeOne} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={nubeeTwo} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={nubeeThree} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={nubeeFour} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={nubeeFive} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="mx-auto pb-5" src={nubeeSix} />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="text-center mt-10">
          <Button
            title="Get your licence"
            href="/drivers-vehicle-seletion"
          ></Button>
        </div>
      </section>

      <div className="border-b-2 border-black mt-24 w-32 mx-auto"></div>

      <section className="mt-24 px-6 md:pr-16">
        <div className="flex flex-col md:flex-row gap-16 md:gap-32 items-center px-6 md:max-w-7xl mx-auto">
          <img className="w-full md:w-5/12" src={map} />
          <div>
            <h1 className="mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
              Where we operate in South Africa.
            </h1>
            <div className="grid grid-cols-2 gap-5 mt-10">
              <div>
                <img className="inline-block pr-5" src={locationIcon} />
                Cape Town
              </div>
              <div>
                <img className="inline-block pr-5" src={locationIcon} />
                Gqeberha (Port Elizabeth)
              </div>
              <div>
                <img className="inline-block pr-5" src={locationIcon} />
                Durban
              </div>
              <div>
                <img className="inline-block pr-5" src={locationIcon} />
                Johannesburg
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="border-b-2 border-nubee-blue mt-24 w-32 mx-auto"></div>

      <section className="px-6 md:px-28 mt-10 md:mt-24 py-10 md:py-16 md:px-36 text-center">
        {/*        <p className="text-black text-center md:w-2/4 mx-auto pt-5">Subscribe to our driving community and get the latest Nubee news and updates.</p>
        <form className="flex flex-col md:flex-row justify-center items-center mx-auto">
          <div className="pt-5 w-full md:w-1/3">
            <input
              className="w-full rounded-md border-gray-800 h-12 text-center md:text-left"
              id="first-name"
              placeholder="Email Address"
              type="email"
            />
          </div>
          <Button title="Sign up"></Button>
        </form>*/}
        <h2 className="font-bold pt-14">
          Follow us on all of our platforms to stay connected.
        </h2>
        <div className="flex mx-auto gap-5 mt-5 justify-center">
          <Link
            to="https://www.facebook.com/nubeeapp?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-8" src={facebookIcon} />
          </Link>
          <Link
            to="https://www.linkedin.com/company/nubeehq/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-8" src={linkedinIcon} />
          </Link>
          <Link
            to="https://instagram.com/nubeehq?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-8" src={instagramIcon} />
          </Link>
          <Link
            to="https://www.tiktok.com/@nubeeapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-8" src={tiktokIcon} />
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage;
